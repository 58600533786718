"use strict";

export default {
    hasClass: function(el, className){
        return el.classList ? el.classList.contains(className) : new RegExp('\\b'+ className+'\\b').test(el.className);
    },
    addClass: function (el, className) {
        if (el.classList)
            el.classList.add(className);
        else if (!this.hasClass(el, className)) el.className += ' ' + className;
    },
    removeClass: function (el, className) {
        if (el.classList)
            el.classList.remove(className);
        else el.className = el.className.replace(new RegExp('\\b'+ className+'\\b', 'g'), '');
    },
    getStyle: function (el, styleProp) {
        if (el.currentStyle)
            return el.currentStyle[styleProp];
        return document.defaultView.getComputedStyle(el,null)[styleProp];
    },
    setStyle: function (el, styles) {
        for (var property in styles)
            el.style[property] = styles[property];
    },
    toggle: function (el, style){
        if(el.classList)
            el.classList.toggle(style);
        else{
            if(this.hasClass(el, style))
                this.removeClass(el, style);
            else
                this.addClass(el, style);
        }
    },
    positionQualifyingArray: ['relative', 'absolute', 'fixed']
};
