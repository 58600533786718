"use strict";

export default {
    all: function (selector, context) {
        return (context || document).querySelectorAll(selector);
    },
    one: function (selector, context) {
        return (context || document).querySelector(selector);
    },
    id:  function (id) {
        return document.getElementById(id);
    }
};
