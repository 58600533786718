"use strict";

import Css from './css';

export default {
    modal: {
        el: document.createElement('div'),
        class: 'modal',
        activeClass: 'modal--open',
        closingClass: 'modal--closing',
        activeBodyClass: 'body__modal--open',
        attribute: 'data-modal',
        onOpenCallback: function(){},
        onCloseCallback: function(){}
    },
    body: {
        el: document.createElement('div'),
        class: 'modal__body'
    },
    closeOnBlur: false,
    content: {
        content: '',
        class: 'modal__content'
    },
    btnClass: 'modal__btn',
    btnAttribute: 'data-modal-button',
    btnClose: {
        class: '',
        title: 'Close modal window',
        url: '#',
        text: 'X',
        callback: function(){}
    },
    btnCancel: {
        class: '',
        title: 'Cancel',
        url: '#',
        text: 'Cancel',
        callback: function(){}
    },
    btnAccept: {
        class: '',
        title: 'Accept',
        url: '#',
        text: 'Continue',
        callback: function(){}
    },
    header: {
        display: true,
        class: 'modal__header',
        rowClass: 'modal__header__row',
        fixed: false,
        btnClose: true,
        btnCloseClass: 'modal__header__btn--close',
        closeColumnClass: 'modal__header__close__column',
        title: '',
        titleColumnClass: 'modal__header__title__column',
        titleClass: 'modal__header__title'
    },
    footer: {
        display: true,
        class: 'modal__footer',
        fixed: false,
        btnClose: false,
        btnCancel: true,
        btnAccept: true,
        content: ''
    },
    init: function(){
        Css.addClass(this.modal.el, this.modal.class);
        this.modal.el.setAttribute(this.modal.attribute, '');
        this.modal.el.setAttribute('role', 'dialog');
        Css.addClass(this.body.el, this.body.class);
        this.modal.el.appendChild(this.body.el);
        this.addModalElementEventListener();
        this.addModalBodyEventListener();
        this.modal.onOpenCallback();
    },
    setCustomContent: function(content){
        this.content.content = content || '';
    },
    open: function(content, callback){
        if( !content && this.content.content !== '') {
            this.body.el.innerHTML = this.content.content;
        }
        else{
            this.body.el.innerHTML = this.getModalHeaderHtmlMarkup(this.header.title)
                + this.getModalContentHtmlMarkup(content)
                + this.getModalFooterHtmlMarkup(this.footer.content, this.footer.fixed)
                + '</div>';
        }
        document.body.appendChild(this.modal.el);
        Css.addClass(this.modal.el, this.modal.activeClass);
        Css.addClass(document.body, this.modal.activeBodyClass);
        if(typeof callback === 'function') {
            callback(this.body.el);
        }
    },
    addModalElementEventListener: function(){
        if(this.closeOnBlur){
            var self = this;
            this.modal.el.addEventListener('click', function(e){
                e.preventDefault();
                self.close();
            }, false);
        }
    },
    addModalBodyEventListener: function(){
        var self = this;
        this.body.el.addEventListener('click', function(e){
            var button = e.target.getAttribute('data-modal-button')
                || e.target.parentNode.getAttribute('data-modal-button') || null;
            e.stopPropagation();
            switch (button){
                case 'close':
                    self.close(e);
                    self.btnClose.callback(e);
                    break;
                case 'cancel':
                    self.close(e);
                    self.cancel(e);
                    break;
                case 'accept':
                    self.accept(e);
                    break;
            }
        }, false);
    },
    accept: function(e){
        this.btnAccept.callback(e);
    },
    cancel: function(e){
        this.btnCancel.callback(e);
    },
    close: function(e){
        Css.removeClass(document.body, this.modal.activeBodyClass);
        Css.addClass(this.modal.el, this.modal.closingClass);
        Css.removeClass(this.modal.el, this.modal.activeClass);
        var self = this,
            destroy = function(){
                self.destroy();
            },timeoutID = window.setTimeout(destroy, 500);
        this.modal.onCloseCallback();
    },
    destroy: function(){
        Css.removeClass(this.modal.el, this.modal.activeClass);
        Css.removeClass(this.modal.el, 'modal--closing');
        if( this.modal.el.parentNode !== null ){
            this.modal.el.parentNode.removeChild(this.modal.el);
        }
    },
    getModalHeaderHtmlMarkup: function(content){
        var headerHtml = '';
        if(this.header.display) {
            var closeColumn = this.header.btnClose
                ? '<div class="' + this.header.closeColumnClass + '">' + this.getCloseButton(this.header.btnCloseClass) + '</div>'
                : '';
            headerHtml = '<div class="' + this.header.class + ( this.header.fixed ? '--fixed' : '' ) + '">'
                + '<div class="' + this.header.rowClass + '">'
                + '<div class="' + this.header.titleColumnClass + '">'
                + '<h4 class="' + this.header.titleClass + '">' + content + '</h4>'
                + '</div>'
                + closeColumn
                + '</div>'
                + '</div>';
        }
        return headerHtml;
    },
    getModalContentHtmlMarkup: function(content){
        return '<div class="'+ this.content.class +'">'+ content +'</div>';
    },
    getModalFooterHtmlMarkup: function(content){
        var footerHtml = '',
            cancelButton = this.footer.btnCancel ? this.getCancelButton() : '';
        if(this.footer.display) {
            footerHtml = '<div class="' + this.footer.class + ( this.footer.fixed ? '--fixed' : '' ) + '">'
                + content + this.getAcceptButton() + cancelButton + '</div>';
        }
        return footerHtml;
    },
    getCloseButton: function(extraClass){
        var btnClass = extraClass || '';
        return this.getButtonHtml('close', this.btnClose.text, this.btnClose.title, this.btnClose.url, this.btnClose.class + ' ' + btnClass);
    },
    getCancelButton: function(extraClass){
        var btnClass = extraClass || '';
        return this.getButtonHtml('cancel', this.btnCancel.text, this.btnCancel.title, this.btnCancel.url, this.btnCancel.class + ' ' + btnClass);
    },
    getAcceptButton: function(extraClass){
        var btnClass = extraClass || '';
        return this.getButtonHtml('accept', this.btnAccept.text, this.btnAccept.title, this.btnAccept.url, this.btnAccept.class + ' ' + btnClass);
    },
    getButtonHtml: function(type, content, title, url, btnClass){
        var btnClass = btnClass || '';
        return '<a href="' + url + '" '
                + 'title="' + title + '" '
                + 'class="' + this.btnClass + ' ' + this.btnClass + '--' + type +' ' + btnClass + '" '
                + this.btnAttribute + '="'+ type +'" >'
                + content
                + '</a>';
    }
};
